<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <startup-statistics />
      </b-col>
      <b-col md="12">
        <mentor-statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="7">
        <program-statistics />
      </b-col>
      <b-col md="5">
        <student-statistics />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import MentorStatistics from '@/views/dashboard/v1/components/incubator/MentorStatistics.vue'
import StudentStatistics from '@/views/dashboard/v1/components/incubator/StudentStatistics.vue'
import StartupStatistics from '@/views/dashboard/v1/components/incubator/StartupStatistics.vue'
import ProgramStatistics from '@/views/dashboard/v1/components/incubator/ProgramStatistics.vue'

export default {
  components: {
    StudentStatistics,
    MentorStatistics,
    StartupStatistics,
    ProgramStatistics,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
