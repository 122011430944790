<template>

  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <b-card-header>
        <b-card-title>
          Programs
        </b-card-title>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="outline-primary"
          :to="{name: 'manage-programs'}"
        >View Details
        </b-button>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="4"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-card-text class="mb-50">
                  {{ item.title }}
                </b-card-text>
                <h4 class="font-weight-bolder">
                  {{ item.total }}
                </h4>
                <hr>
                <h5>
                  <strong>{{ item.applicants }}</strong>
                </h5>
                <b-card-text class="font-weight-light">
                  Applicants
                </b-card-text>
                <hr>
                <h5>
                  <strong>{{ item.participants }}</strong>
                </h5>
                <b-card-text class="font-weight-light">
                  Participants
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      data: [
        {
          icon: 'ActivityIcon',
          color: 'light-primary',
          title: 'Draft',
          total: null,
          applicants: null,
          participants: null,
        },
        {
          icon: 'ClockIcon',
          color: 'light-info',
          title: 'Scheduled',
          total: null,
          applicants: null,
          participants: null,
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: 'Completed',
          total: null,
          applicants: null,
          participants: null,
        },
      ],
      programs: {},
    }
  },
  apollo: {
    programs: {
      query: gql`
        query dashboard_programs($incubator_id: Int!) {
          programs_basicinfo(where: {organization_id_id: {_eq: $incubator_id}}) {
            status
          }
          programs_applicantstable(where: {programs_basicinfo: {organization_id_id: {_eq: $incubator_id}}}) {
            programs_basicinfo {
              status
            }
          }
          programs_startupparticipants(where: {programs_applicantstable: {programs_basicinfo: {organization_id_id: {_eq: $incubator_id}}}}) {
            programs_applicantstable {
              programs_basicinfo {
                status
              }
            }
          }
        }
      `,
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const programs = data.programs_basicinfo
        const applicants = data.programs_applicantstable
        const participants = data.programs_startupparticipants
        // Upcoming
        this.data[1].total = programs.filter(e => e.status && e.status.toLowerCase() === 'draft').length
        this.data[1].applicants = applicants.filter(e => e.programs_basicinfo.status && e.programs_basicinfo.status.toLowerCase() === 'draft').length
        this.data[1].participants = participants.filter(e => e.programs_applicantstable.programs_basicinfo.status && e.programs_applicantstable.programs_basicinfo.status.toLowerCase() === 'draft').length
        // Completed
        this.data[2].total = programs.filter(e => e.status && e.status.toLowerCase() === 'completed').length
        this.data[2].applicants = applicants.filter(e => e.programs_basicinfo.status && e.programs_basicinfo.status.toLowerCase() === 'completed').length
        this.data[2].participants = participants.filter(e => e.programs_applicantstable.programs_basicinfo.status && e.programs_applicantstable.programs_basicinfo.status.toLowerCase() === 'completed').length
        // Ongoing = total - (upcoming + completed)
        this.data[0].total = programs.length - this.data[1].total - this.data[2].total
        this.data[0].applicants = applicants.length - this.data[1].applicants - this.data[2].applicants
        this.data[0].participants = participants.length - this.data[1].participants - this.data[2].participants
        return data
      },
    },
  },
}
</script>
